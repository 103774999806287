import { Contentful_BlockTable, Maybe, Scalars } from "graphql-types";
import React, { useEffect, useState } from "react";
import { BlockSpaceJson, SpacerDefaultProps, translateBlockSpacing } from "../../utils/translate-cf-spacing";
import { PropsWithLocation } from "../../utils/types";

/**
 * If a table has over 110 characters the height would increase too much - then we need to increase
 * table min width to compensate it
 */
const MOBILE_BASE_CONTENT_SIZE = 110;

function parseContentfulTable(table: Maybe<Scalars["Contentful_JSON"]>) {
  if (!table) return null;

  const headers = table.tableData[0].map((h: any) =>
    Object.assign(h, {
      contentSize: 0,
    })
  );
  const rows = table.tableData.slice(1, table.tableData.length);

  const rowsContentSize = rows.filter((x: any) => x).map((row: any) => row.map((rowContent: any) => rowContent?.length));
  headers.forEach((h: any, index: number) => {
    rowsContentSize.forEach((x: any) => {
      if (x[index] > h.contentSize) {
        h.contentSize = x[index];
      }
    });
  });

  const totalContentSize = headers.reduce((prev: any, x: any) => prev + x.contentSize, 0);
  headers.map((h: any) =>
    Object.assign(h, {
      width: Math.round((h.contentSize * 100) / totalContentSize),
    })
  );

  return {
    headers,
    rows,
    maxContentSize: headers.concat().sort((a, b) => b.contentSize - a.contentSize)[0].contentSize,
  };
}

const renderTableRow = (headers: any, row: any, rowIndex: number) => {
  return (
    <tr key={rowIndex}>
      {row.map((x: any, itemIndex: number) => (
        <td
          style={{ width: `${headers[itemIndex].width}%` }}
          className="first:rounded-l-lg last:rounded-r-lg py-4 pl-8 bg-white"
          key={`${rowIndex}${itemIndex}`}
        >
          <div dangerouslySetInnerHTML={{ __html: x }} />
        </td>
      ))}
    </tr>
  );
};

const Table: React.FC<PropsWithLocation<Contentful_BlockTable>> = (props) => {
  if (!props?.sys) return null;

  const topSpaceDefault: SpacerDefaultProps = {
    sm: 4,
    md: 4,
    lg: 4,
  };

  const bottomSpace: SpacerDefaultProps = {
    sm: 4,
    md: 4,
    lg: 4,
  };
  const topSpaceClasses = translateBlockSpacing("top", props.topSpace as BlockSpaceJson, topSpaceDefault);
  const bottomSpaceClasses = translateBlockSpacing("bottom", props.bottomSpace as BlockSpaceJson, bottomSpace);

  const [tableMinWidth, setTableMinWidth] = useState(100);
  const table = parseContentfulTable(props.table);

  useEffect(() => {
    function calculateTableMinWidth() {
      if (window.screen.width < 800) {
        const result = Math.round(table?.maxContentSize / MOBILE_BASE_CONTENT_SIZE);

        if (result > 1) {
          setTableMinWidth(100 * result);
        } else {
          setTableMinWidth(100);
        }
      } else {
        setTableMinWidth(100);
      }
    }

    calculateTableMinWidth();

    window.addEventListener("resize", calculateTableMinWidth);

    return () => window.removeEventListener("resize", calculateTableMinWidth);
  }, [table?.maxContentSize]);

  return (
    <div className={`container ${topSpaceClasses} ${bottomSpaceClasses}  mx-auto px-6`}>
      <div className="overflow-x-auto  w-full h-full">
        <table
          style={{ minWidth: `${tableMinWidth}%` }}
          className="block table-auto  overflow-x-auto  border-collapse table-component w-full"
        >
          <thead className="text-white">
            <tr>
              {table?.headers.map((header: any, key: number) => (
                <th
                  style={{ width: `${header.width}%` }}
                  align="left"
                  className="bg-slate-300 whitespace-nowrap px-8 py-4 first:rounded-l-lg  last:rounded-r-lg "
                  key={key}
                >
                  <div dangerouslySetInnerHTML={{ __html: header }} />
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">{table?.rows.map((r: any, index: number) => renderTableRow(table?.headers, r, index))}</tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(Table);
